// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.About {
  width: 100%;
  height: auto;
  margin: 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
video {
  width: 474px;
  height: 611px;
}

.About-Box1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.About-Box1-Box1 {
  width: 50%;
  height: 567px;
  margin: 0px 18px;
}

.About-Box1-Box2 {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
.About-Box2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: "row";
  width: 90%;
  height: 100%;
  border-bottom: 1px solid #bd9334;
  padding: 52px 0px;
}

@media only screen and (max-width: 800px) {
  .About-Box1-Box1 {
    display: none;
  }

  video {
    width: auto;
    height: 611px;
  }
  .About-Box1-Box2 {
    width: 90%;
  }
}
@media only screen and (min-width: 2560px) {
  .About-Box1-Box2 {margin-left: -11pc;
  }
  
}

`, "",{"version":3,"sources":["webpack://./src/Pages/About/About.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;AACxB;AACA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,YAAY;AACd;AACA;EACE,UAAU;EACV,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,sBAAsB;EACtB,YAAY;AACd;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,qBAAqB;EACrB,UAAU;EACV,YAAY;EACZ,gCAAgC;EAChC,iBAAiB;AACnB;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,WAAW;IACX,aAAa;EACf;EACA;IACE,UAAU;EACZ;AACF;AACA;EACE,kBAAkB,kBAAkB;EACpC;;AAEF","sourcesContent":[".About {\n  width: 100%;\n  height: auto;\n  margin: 0px 0px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\nvideo {\n  width: 474px;\n  height: 611px;\n}\n\n.About-Box1 {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  height: 100%;\n}\n.About-Box1-Box1 {\n  width: 50%;\n  height: 567px;\n  margin: 0px 18px;\n}\n\n.About-Box1-Box2 {\n  width: 50%;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  flex-direction: column;\n  height: 100%;\n}\n.About-Box2 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: \"row\";\n  width: 90%;\n  height: 100%;\n  border-bottom: 1px solid #bd9334;\n  padding: 52px 0px;\n}\n\n@media only screen and (max-width: 800px) {\n  .About-Box1-Box1 {\n    display: none;\n  }\n\n  video {\n    width: auto;\n    height: 611px;\n  }\n  .About-Box1-Box2 {\n    width: 90%;\n  }\n}\n@media only screen and (min-width: 2560px) {\n  .About-Box1-Box2 {margin-left: -11pc;\n  }\n  \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
