// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Success-Box
{
    border:2px solid #E2BF44;
    font-family: 'Poppins';
    margin: 20px 20px;
    border-radius: 10px;
}

.Success-Box-Details{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    border-radius: 2rem;
    padding: 3rem;
}

@media only screen and (max-width: 600px)  {
    .Order-Detail-Box{
        flex-direction: column;
    }
    .Success-Box-Details{
        padding: 1rem;
    }
  }
`, "",{"version":3,"sources":["webpack://./src/Pages/Success/Success.css"],"names":[],"mappings":"AAAA;;IAEI,wBAAwB;IACxB,sBAAsB;IACtB,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI;QACI,sBAAsB;IAC1B;IACA;QACI,aAAa;IACjB;EACF","sourcesContent":[".Success-Box\n{\n    border:2px solid #E2BF44;\n    font-family: 'Poppins';\n    margin: 20px 20px;\n    border-radius: 10px;\n}\n\n.Success-Box-Details{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    background: white;\n    border-radius: 2rem;\n    padding: 3rem;\n}\n\n@media only screen and (max-width: 600px)  {\n    .Order-Detail-Box{\n        flex-direction: column;\n    }\n    .Success-Box-Details{\n        padding: 1rem;\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
