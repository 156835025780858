// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer-Navbar {
    position: fixed;
    bottom: 0px;
    height: 50px;
    width: 100%;
    display: none;
     background-color: white;
     box-shadow: 1px 1px 2px 2px grey;
     z-index: 100000;;
 }

 .Profile--Footer-Navbar-Box-Text{
    display:flex;
    justify-content:center;
    align-items:center;
    border-radius:50%;
    height:30px;
    width:30px;
    margin:0px 6px;
    font-family:Inter;
    font-weight:700;
    color:white;
    border: none;
    background-color:red;
  }

  .Footer-Navlink-Navbar{
    border: grey;
    text-decoration:none;
    color:#000000;
  
  }

 @media only screen and (max-width: 700px){
    .Footer-Navbar {
        display: block;
     }
}`, "",{"version":3,"sources":["webpack://./src/Components/FooterNavbar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,WAAW;IACX,aAAa;KACZ,uBAAuB;KACvB,gCAAgC;KAChC,eAAe;CACnB;;CAEA;IACG,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,UAAU;IACV,cAAc;IACd,iBAAiB;IACjB,eAAe;IACf,WAAW;IACX,YAAY;IACZ,oBAAoB;EACtB;;EAEA;IACE,YAAY;IACZ,oBAAoB;IACpB,aAAa;;EAEf;;CAED;IACG;QACI,cAAc;KACjB;AACL","sourcesContent":[".Footer-Navbar {\n    position: fixed;\n    bottom: 0px;\n    height: 50px;\n    width: 100%;\n    display: none;\n     background-color: white;\n     box-shadow: 1px 1px 2px 2px grey;\n     z-index: 100000;;\n }\n\n .Profile--Footer-Navbar-Box-Text{\n    display:flex;\n    justify-content:center;\n    align-items:center;\n    border-radius:50%;\n    height:30px;\n    width:30px;\n    margin:0px 6px;\n    font-family:Inter;\n    font-weight:700;\n    color:white;\n    border: none;\n    background-color:red;\n  }\n\n  .Footer-Navlink-Navbar{\n    border: grey;\n    text-decoration:none;\n    color:#000000;\n  \n  }\n\n @media only screen and (max-width: 700px){\n    .Footer-Navbar {\n        display: block;\n     }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
