// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-table-container {
  font-family: Abhaya Libre;
  font-size: 64px;
  font-weight: 400;
  line-height: 76px;
  letter-spacing: 0em;
  text-align: left;
}

.order .table-head  p {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Order/Order.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".order-table-container {\n  font-family: Abhaya Libre;\n  font-size: 64px;\n  font-weight: 400;\n  line-height: 76px;\n  letter-spacing: 0em;\n  text-align: left;\n}\n\n.order .table-head  p {\n  font-family: Poppins;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 21px;\n  letter-spacing: 0em;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
