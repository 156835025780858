// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer {
  background-color: #bd970c;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
}

.Footer-inner-box {
  height: 90%;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.silicon-garage {
  color: black;
  cursor: pointer;
}

.v1 {
  border-left: 1px solid rgb(0, 0, 0);
  height: 240px;
}

@media only screen and (max-width: 600px) {
  .Footer-inner-box {
    width: 90%;
  }
  .socialMedia {
    margin-top: 0px !important;
  }

  .v1 {
    display: none;
  }

  .mdi {
    display: none;
  }
}
@media only screen and (max-width: 700px) {
  .Footer {
    margin: 0px 0px 50px 0px;
  }
  .v1 {
    display: none;
  }
  .socialMedia {
    margin-top: 0px !important;
  }

  .mdi {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Footer.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,mCAAmC;EACnC,aAAa;AACf;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,0BAA0B;EAC5B;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,aAAa;EACf;AACF;AACA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,aAAa;EACf;EACA;IACE,0BAA0B;EAC5B;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":[".Footer {\n  background-color: #bd970c;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-family: Roboto;\n}\n\n.Footer-inner-box {\n  height: 90%;\n  width: 90%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.silicon-garage {\n  color: black;\n  cursor: pointer;\n}\n\n.v1 {\n  border-left: 1px solid rgb(0, 0, 0);\n  height: 240px;\n}\n\n@media only screen and (max-width: 600px) {\n  .Footer-inner-box {\n    width: 90%;\n  }\n  .socialMedia {\n    margin-top: 0px !important;\n  }\n\n  .v1 {\n    display: none;\n  }\n\n  .mdi {\n    display: none;\n  }\n}\n@media only screen and (max-width: 700px) {\n  .Footer {\n    margin: 0px 0px 50px 0px;\n  }\n  .v1 {\n    display: none;\n  }\n  .socialMedia {\n    margin-top: 0px !important;\n  }\n\n  .mdi {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
