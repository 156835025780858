// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Exchange-Main-Box{
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    margin: 100px 100px;
    font-family: Inter;
}

.Exchange-Policy-Heading
{
    font-size: 60px;
    margin: 0px 0px 60px 0px;
    font-weight: 600;
}

@media only screen and (max-width: 1000px) and (min-width: 600px){
    .Exchange-Policy-Heading
    {
        font-size: 40px;
    
    }
    
    }


    @media only screen and (max-width: 600px){
        .Exchange-Policy-Heading
        {
            font-size: 25px;
        
        }
        .Exchange-Main-Box{
            
            margin: 50px 10px ;
           
        }
        
        }`, "",{"version":3,"sources":["webpack://./src/Pages/ExchangePolicy/ExchangePolicy.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,qBAAqB;IACrB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;;IAEI,eAAe;IACf,wBAAwB;IACxB,gBAAgB;AACpB;;AAEA;IACI;;QAEI,eAAe;;IAEnB;;IAEA;;;IAGA;QACI;;YAEI,eAAe;;QAEnB;QACA;;YAEI,kBAAkB;;QAEtB;;QAEA","sourcesContent":[".Exchange-Main-Box{\n    display:flex;\n    justify-content:center;\n    align-items:center;\n    flex-direction:column;\n    margin: 100px 100px;\n    font-family: Inter;\n}\n\n.Exchange-Policy-Heading\n{\n    font-size: 60px;\n    margin: 0px 0px 60px 0px;\n    font-weight: 600;\n}\n\n@media only screen and (max-width: 1000px) and (min-width: 600px){\n    .Exchange-Policy-Heading\n    {\n        font-size: 40px;\n    \n    }\n    \n    }\n\n\n    @media only screen and (max-width: 600px){\n        .Exchange-Policy-Heading\n        {\n            font-size: 25px;\n        \n        }\n        .Exchange-Main-Box{\n            \n            margin: 50px 10px ;\n           \n        }\n        \n        }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
