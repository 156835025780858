// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shipping-policy-title {
  font-family: "Abhaya Libre";
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}

.thank-you {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.small-text {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.ShipingPolicy ul li {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.ShipingPolicy ul li span {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/ShippingPolicy/shipingPolicy.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".shipping-policy-title {\n  font-family: \"Abhaya Libre\";\n  font-size: 32px;\n  font-weight: 400;\n  line-height: 38px;\n  letter-spacing: 0em;\n  text-align: left;\n}\n\n.thank-you {\n  font-family: \"Poppins\";\n  font-size: 20px;\n  font-weight: 600;\n  line-height: 30px;\n  letter-spacing: 0em;\n  text-align: left;\n}\n\n.small-text {\n  font-family: \"Poppins\";\n  font-size: 20px;\n  font-weight: 400;\n  line-height: 30px;\n  letter-spacing: 0em;\n  text-align: left;\n}\n\n.ShipingPolicy ul li {\n  font-family: \"Poppins\";\n  font-size: 20px;\n  font-weight: 400;\n  line-height: 30px;\n  letter-spacing: 0em;\n  text-align: left;\n}\n\n.ShipingPolicy ul li span {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
