// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login-Button {
    width: 60%;
    height: 50px;
    border-radius: 72px;
    background-color: #e2bf44;
    color: white;
    padding: 19px 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    border: none;
    margin: 20px 0px;
  }
  
  .red_color {
    color: red;
  }
  
  .LoginImage {
    width: 50%;
    height: 100%;
  }
  
  .LoginDetails {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
  
  .otpinput input{
    width: 3rem !important;
    height: 3rem !important;
  }
  .Login-Heading2 {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 20px;
  }
  @media only screen and (max-width: 800px) {
    .LoginImage {
      display: none;
    }
    .LoginDetails {
      width: 100%;
    }
  
    .Login-Button {
      width: 100%;
    }
    .Login-Heading2 {
      font-size: 16px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/Pages/OtpVerification/OtpVerification.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;IACV,YAAY;EACd;;EAEA;IACE,UAAU;IACV,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,sBAAsB;IACtB,uBAAuB;EACzB;EACA;IACE,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;EACjB;EACA;IACE;MACE,aAAa;IACf;IACA;MACE,WAAW;IACb;;IAEA;MACE,WAAW;IACb;IACA;MACE,eAAe;IACjB;EACF","sourcesContent":[".Login-Button {\n    width: 60%;\n    height: 50px;\n    border-radius: 72px;\n    background-color: #e2bf44;\n    color: white;\n    padding: 19px 48px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-size: 30px;\n    border: none;\n    margin: 20px 0px;\n  }\n  \n  .red_color {\n    color: red;\n  }\n  \n  .LoginImage {\n    width: 50%;\n    height: 100%;\n  }\n  \n  .LoginDetails {\n    width: 50%;\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    flex-direction: column;\n    height: 100%;\n  }\n  \n  .otpinput input{\n    width: 3rem !important;\n    height: 3rem !important;\n  }\n  .Login-Heading2 {\n    font-family: \"Poppins\";\n    font-weight: 500;\n    font-size: 20px;\n  }\n  @media only screen and (max-width: 800px) {\n    .LoginImage {\n      display: none;\n    }\n    .LoginDetails {\n      width: 100%;\n    }\n  \n    .Login-Button {\n      width: 100%;\n    }\n    .Login-Heading2 {\n      font-size: 16px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
