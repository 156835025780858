// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Privacy-Heading{
    font-weight: 600;
}

.Privacy-Policy-Heading{
    font-size: 60px;
    margin: 0px 0px 60px 0px;
    font-weight: 600;
}

.Privacy-Main-Box{
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    margin: 100px 100px;
    font-family: Inter;
}


@media only screen and (max-width: 800px)  {
    .Privacy-Main-Box{
        margin: 40px 10px;
    }
    .Privacy-Policy-Heading{
        font-size: 40px;
        
    }
  }`, "",{"version":3,"sources":["webpack://./src/Pages/PrivacyPolicy/Privacy.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,wBAAwB;IACxB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,qBAAqB;IACrB,mBAAmB;IACnB,kBAAkB;AACtB;;;AAGA;IACI;QACI,iBAAiB;IACrB;IACA;QACI,eAAe;;IAEnB;EACF","sourcesContent":[".Privacy-Heading{\n    font-weight: 600;\n}\n\n.Privacy-Policy-Heading{\n    font-size: 60px;\n    margin: 0px 0px 60px 0px;\n    font-weight: 600;\n}\n\n.Privacy-Main-Box{\n    display:flex;\n    justify-content:center;\n    align-items:center;\n    flex-direction:column;\n    margin: 100px 100px;\n    font-family: Inter;\n}\n\n\n@media only screen and (max-width: 800px)  {\n    .Privacy-Main-Box{\n        margin: 40px 10px;\n    }\n    .Privacy-Policy-Heading{\n        font-size: 40px;\n        \n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
